import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { NotificationService } from 'src/app/shared/Notification.service';

import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  constructor(
    public DownloadService: DownloadService,
    public NotificationService: NotificationService,

    public ThanhVienService: ThanhVienService,
    public ToChucThanhVienService: ToChucThanhVienService,
  ) {
    this.getByQueryString();
  }
  ngOnInit() {
  }
  getByQueryString() {
    this.isShowLoading = true;
    this.ThanhVienService.GetByIDAsync(environment.InitializationNumber).subscribe(
      res => {
        this.ThanhVienService.formDataLogin = res as ThanhVien;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.ThanhVienService.AuthenticationAsync(form.value).subscribe(
      res => {
        this.isShowLoading = false;
        this.ThanhVienService.formDataLogin = res as ThanhVien;
        if (this.ThanhVienService.formDataLogin) {
          if (this.ThanhVienService.formDataLogin.ParentID == 1) {
            if (this.ThanhVienService.formDataLogin.Note) {
              let Token = "" + environment.InitializationNumber;
              if (this.ThanhVienService.formDataLogin.Note.split('=').length > 0) {
                Token = this.ThanhVienService.formDataLogin.Note.split('=')[1];
              }
              this.ToChucThanhVienService.baseParameter.ThanhVienID = this.ThanhVienService.formDataLogin.ID;
              this.ToChucThanhVienService.GetByThanhVienIDToListAsync().subscribe(
                res => {
                  this.ToChucThanhVienService.list = (res as ToChucThanhVien[]);
                  if (this.ToChucThanhVienService.list) {
                    if (this.ToChucThanhVienService.list.length > 0) {
                      let ToChucID = this.ToChucThanhVienService.list[0].ParentID


                      this.ThanhVienService.formDataLogin.TypeName = this.ThanhVienService.formDataLogin.TypeName + "index.html?" + environment.Token + "=" + Token + "&data=" + ToChucID;
                      window.location.href = this.ThanhVienService.formDataLogin.TypeName;
                    }
                  }
                },
                err => {
                  this.NotificationService.warn(environment.LoginNotSuccess);
                  this.isShowLoading = false;
                }
              );
            }
            else {
              this.NotificationService.success(environment.LoginNotSuccess);
            }
          }
          else {
            if (this.ThanhVienService.formDataLogin.Note) {
              window.location.href = this.ThanhVienService.formDataLogin.Note;
            }
            else {
              this.NotificationService.success(environment.LoginNotSuccess);
            }
          }
        }
      },
      err => {
        this.NotificationService.warn(environment.LoginNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  onVietNam() {
    this.DownloadService.LanguageID = true;
    this.DownloadService.ChangeLanguage();
  }
  onEnglish() {
    this.DownloadService.LanguageID = false;
    this.DownloadService.ChangeLanguage();
  }
}
